import React from "react";
import {Link} from "gatsby";

function ModalSuccess({show, onClose}) {
    if (!show) {
        return null;
    }

    const refreshPage = () => {
        window.location.reload(false);
    }

    return (
        <div className="modal-overlay">
            <div className="modal">
                <div className="modal-content">
                    <img style={{paddingBottom: 20}} src={require('../assets/img/fail.svg')} width={150}
                         height='auto'/>
                    <h1 className="services_section_1__title" style={{color: '#000000'}}>Message not sent</h1>
                    <p className="services_section_1__paragraph">There was a problem sending the email, please try again</p>
                    <button className="button" onClick={() => refreshPage()}>Agree</button>
                </div>
            </div>
        </div>
    )
}

export default ModalSuccess;
