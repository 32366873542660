import React from "react";
import {Link} from "gatsby";

function ModalSuccess({show, onClose}) {
    if (!show) {
        return null;
    }


    return (
        <div className="modal-overlay">
            <div className="modal">
                <div className="modal-content">
                    <img style={{paddingBottom: 20}} src={require('../assets/img/success.svg')} width={100}
                         height='auto'/>
                    <h1 className="services_section_1__title" style={{color: '#000000'}}>Message sent succesfully</h1>
                    <p className="services_section_1__paragraph">Thank you for contacting us, we will respond as soon as possible</p>
                    <Link to="/" className="button">Okay</Link>
                </div>
            </div>
        </div>
    )
}

export default ModalSuccess;
